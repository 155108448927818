<template>
  <div class="news-detail">
    <div class="news-title">{{ info.title }}</div>
    <div class="news-info">
      <div class="news-time">{{ info.post_at }}</div>
      <div class="news-view">
        <t-icon name="browse"></t-icon>{{ info.flowrate }}
      </div>
    </div>
    <div class="work-video" v-if="info.vid != ''">
      <div id="container" class="video-wrapper">
        <div id="vidro-content"></div>
      </div>
    </div>
    <div class="rich-txt">
      <div v-html="$decodeContent(info.content)"></div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { fetchArticleDetail } from "../network/API";
import tools from "../utils/tools.js";
import "../utils/txplayer.js";
const player = ref(null);
const route = useRoute();
const info = ref({});
onMounted(() => {
  getDetail(route.params.id);
});
async function getDetail(id) {
  let res = await fetchArticleDetail(id);
  info.value = res;
  if (info.vid != "") {
    setTimeout(() => {
      playerInit(info.vid);
    }, 1);
  }
  tools.shareInit(res.title, res.cover);
}
function playerInit(vid) {
  player.value = new Txplayer({
    containerId: "container",
    vid: info.value.vid,
    width: "calc(100vw - 30px)",
    height: "178px",
    autoplay: false,
  });
}
</script>
<style scoped>
.news-detail {
  padding: 15px;
  background: #fff;
  /* background: #fff url(/img/banner-pattern.c6a8dca1.png) no-repeat;
    background-size: contain;
    background-attachment: fixed; */
  min-height: 100vh;
}

.news-title {
  color: #222;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}
.news-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: 27px;
  color: #666;
  margin-bottom: 16px;
}
.rich-txt {
  font-size: 16px;
  line-height: 27px;
  color: #333;
}
.news-view {
  display: flex;
  align-items: center;
}
.news-view .t-icon {
  margin-right: 6px;
}
::v-deep img {
  max-width: 100%;
}
</style>
